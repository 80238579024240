import React, { useEffect } from "react";
import "../../scss/_changewords.scss";

const ChangingWords = () => {
    useEffect(() => {
        const animText = document.getElementById("change");

        function changeTextLetter(event) {
            let iteration = 0;
            const initText = event.target.innerText;
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ!';
            let interval = null;
            clearInterval(interval);
            interval = setInterval(() => {
                event.target.innerText = initText
                    .split("")
                    .map((letter, index) => {
                        if (index < iteration) {
                            return event.target.dataset.textValue[index];
                        }
                        return letters[Math.floor(Math.random() * letters.length)];
                    })
                    .join("");
                if (iteration >= event.target.dataset.textValue.length) {
                    clearInterval(interval);
                }
                iteration += 1 / 10;
            }, 50);
        }

        animText.addEventListener("mouseover", (event) => {
            changeTextLetter(event);
        });

        return () => {
            animText.removeEventListener("mouseover", changeTextLetter);
        };
    }, []);

    return (
        <div className="wrapper">
            <h1 id="change" data-text-value="SNEMAMO">
                SNEMAMO
            </h1>
        </div>
    );
};

export default ChangingWords;
