import React from 'react';
import Header from './Components/Header';
import Banner from './Components/Banner';
import Gallery from './Components/Gallery';
import Video from './Components/Video';
import Collabs from './Components/Collabs'; 
import Form from './Components/Form';

import Footer from './Components/Footer';

import Chili from '../img/chili_logo_text_white.svg';
import Iskra from '../img/IskraMedical.png';
import SKZ from '../img/skz_logo.png';
import TMO from '../img/Moonlighting_logo-300x205.png';


import '../scss/main.scss';

function App() {
  return (
    <>
      <Header />
      <Banner />
      <Gallery />
      <Video />
      <Form />
      <Collabs
        items={
          [
            {
              url: Chili,
              alt: 'Chili',
            },
            {
              url: Iskra,
              alt: 'Iskra',
            },
            {
              url: SKZ,
              alt: 'Studentski klub Zalec',
            },
            {
              url: TMO,
              alt: 'The Moonlighting orchestra',
            },
          ]}
      />
      <Footer />
    </>
  );
}

export default App;
