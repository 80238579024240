import React, { useState } from 'react';
import Scenarij from "../../img/Scenarij.jpg";
import EkipaKombi from "../../img/Ekipa_Kombi.jpg";
import Tilen_film from "../../img/Tilen_film.png";
import Dogodek from "../../img/Dogodek.png";
import JanezPost from "../../img/Janez_post.jpg";

import "../../scss/_gallery.scss";

const Gallery = () => {
    const items = [
        { title: "KORPORATIVNI VIDEO", src: Scenarij, className: "corpo-1" },
        { title: "PRENOSI V ŽIVO", src: EkipaKombi, className: "live-2" },
        { title: "FILMI", src: Tilen_film, className: "movies-3" },
        { title: "VIDEOPRODUKCIJA NA DOGODKIH", src: Dogodek, className: "event-4" },
        { title: "PRED in POST PRODUKCIJA", src: JanezPost, className: "pred-post-5" }
    ];

    return (
        <div className="gallery">
            <div className="gallery-container">
                {items.map((item, index) => (
                    <div key={index} className={`gallery-item`}>
                        <img className={`gallery-img ${item.className}`} src={item.src} alt={item.title} />
                        <h2 className='gallery-titles'>{item.title}</h2> 
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
