import React from "react";

import Contacts from "./Contacts";
import Address from "./Address";

import TSP from "../../img/logo_tsp.svg";

import "../../scss/_footer.scss";

const Footer = () => {
    return (
        <footer id="contact">
            <div className="row">
                <div className="clickable">
                <Address
                    url={TSP}
                />
                <Contacts
                    
                    items = {
                        [
                            {
                                title: "+386 70 481 811",
                                url: 'tel:+386 70 481 811',
                            },
                            {
                                title: "info@tsproduction.si",
                                url: 'mailto:info@tsproduction.si',
                            },
                        ]
                    }
                />
                </div>
               
                <div className="copyright">
                    <p>
                        <span className="colorful">©TSProduction </span>
                        Copyright 2022 - 2024</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;