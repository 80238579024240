import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";

import "../../scss/_header.scss";

import logo_tsp from "../../img/logo_tsp.svg";


const Header = () => {
    return (
        <header>
            <Logo url={logo_tsp} />
            <Menu
                items={
                    [
                        {
                            title: "SNEMAMO",
                            url: '#projects',
                        },
                        {
                            title: "PRETEKLI PROJEKTI",
                            url: '#pastprojects'
                        },
                        {
                            title: "GREMO NA KAVO",
                            url: '#contact',
                        },
                        {
                            title: "ZAUPALI SO NAM",
                            url: '#business'
                        }
                    ]
                }
            />
        </header>
    )
}
export default Header;