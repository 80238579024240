import React from "react";
import "../../scss/_address.scss";

const Social = (props) => {
    return (
        <div className="contacts">
            <div className="logo">
                <img src={props.url} alt="Logo" />
            </div>
            <div className="basics">
                <h4>Tilen Smiljan s.p.</h4>
                <p>
                    Partizanska ulica 10
                    <br />
                    3310 Žalec
                </p>
            </div>
    
            </div>
    )
}

export default Social;