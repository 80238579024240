import React, { useState } from "react";

import "../../scss/_menu.scss";

const Menu = () => {
    const menuItems = [
        { title: "SNEMAMO", url: "#projects" },
        { title: "PRETEKLI PROJEKTI", url: "#pastprojects" },
        { title: "GREMO NA KAVO", url: "#contact" },
        { title: "ZAUPALI SO NAM", url: "#business" }
    ];

    return (
        <nav className="MenuNav">
            <ul className="menu">
                {menuItems.map(item => (
                    <li key={item.url}>
                        <a href={item.url}>{item.title}</a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
