import React, { useState } from "react";
import Ekipa from "../../img/Ekipa.jpg";
import "../../scss/_form.scss";
import emailjs from "emailjs-com";

const Form = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the form from submitting normally

        // Send the email using EmailJS
        emailjs.send('service_1oui1hj', 'template_t6xws4n', formData,'5-az2vxuvhPsqHTuO')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                alert('Your message has been sent!');

                // Reset the form
                setFormData({ name: '', company: '', email: '', phone: '', message: '' });
            }, (error) => {
                console.error('Failed to send email:', error);
                alert('An error occurred while sending your message. Please try again later.');
            });
    };

    return (
        <div className="gremo-na-kavo">
            <h3 className="title-2">GREMO NA KAVO</h3>
            <div className="table">
                <div className="form">
                    <form id="contactForm" onSubmit={handleSubmit}>
                        <label className="name" htmlFor="name" alt="Vpisi ime in priimek">Ime in priimek:
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                        </label>
                        <label className="company" htmlFor="company">Podjetje:
                            <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} />
                        </label>
                        <label className="email" htmlFor="email">E-naslov:
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                        </label>
                        <label className="phone" htmlFor="phone">Telefonska številka:
                            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                        </label>
                        <label className="message" htmlFor="message">Opišite nam vaš projekt in vaše želje:
                            <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange}></textarea>
                        </label>
                        <button className="oddaj" type="submit">Oddajte povpraševanje</button>
                    </form>
                </div>
                <div className="photo">
                    <img className="photo-ekipa" src={Ekipa} alt="ozadje" />
                </div>
            </div>
        </div>
    );
}

export default Form;
