import React, { useEffect, useState } from 'react';

import ZNM from "../../img/ZNM.jpg";
import TMO from "../../img/TMO.jpg";
import POINTER from "../../img/ClickON.png";

import "../../scss/_video.scss";



const Video = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [videoId, setVideoId] = useState('');

    const videos = [
        { id: 'fHn4eE_CnPw', title: 'AFTER MOVIES', src: ZNM },
        { id: 'bPb1cwPHCm4', title: 'PRENOS KONCERTA', src: TMO },
    ];

    const openModal = (id) => {
        setVideoId(id);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setVideoId('');
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const modal = document.getElementById('modal');
            if (modal && event.target === modal) {
                closeModal();
            }
        };

        window.addEventListener('click', handleOutsideClick);
        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div>
            <h3 className='title'> PRETEKLI PROJEKTI </h3>
            <div className="galleryVideo">
                {videos.map(video => (
                    <div
                        key={video.id}
                        className="video"
                        onClick={() => openModal(video.id)}
                    >
                        <img src={video.src} alt={video.title} className="srcImg" />
                        <h4 className='titleParagraph'>{video.title}</h4>
                    </div>
                ))}
            </div>

            {modalVisible && (
                <div id="modal" className="modal" style={{ display: 'flex' }}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <iframe
                        title={videoId === 'fHn4eE_CnPw' ? 'VideoZNM' : 'VideoTMO'}
                        id="videoFrame"
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                        frameBorder="0"
                        allowFullScreen
                    />
                </div>
            )}
        </div>
    );
};

export default Video;
