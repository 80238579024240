import React from "react";

import "../../scss/_collabs.scss"

const Collabs = (props) => {
    return (
        <div className="collab">
        <h3 className="title">ZAUPALI SO NAM ...</h3>
        <div className="gallery">
            {props.items.map(item => (
                <img key={item.url} src={item.url} alt={item.alt} />
            ))}
        </div> 
    </div>  
    )
}
export default Collabs;