import React from "react";
import ChangingWords from "./ChangingWords";

import "../../scss/_banner.scss";

const Banner = () => {
    return (
        <div className="banner">
            <ChangingWords />
        </div>
    )
}

export default Banner;