import React from "react";

import LI from "../../img/Linkedin Grey.svg";
import IG from "../../img/ig.svg";

import "../../scss/_contacts.scss";
const Contacts = (props) => {
    return (
      
        <div className="contacts-all">
            <div className="calling">
                {props.items.map(item => (
                    <li key={item.url}>
                        <a href={item.url}>
                            {item.title}
                        </a>
                    </li>
                ))}
            </div>
            <div className="social">
                <li className="IG">
                    <a href="https://www.instagram.com/tsp.production?igsh=ZDNwanQxbXRkY3M=" target="_blank">
                        <img className="social-img" src={IG} alt="Instagram" />
                    </a>
                </li>
                <li className="LI">
                    <a href="https://www.linkedin.com/company/100046006" target="_blank">
                        <img className="social-img" src={LI} alt="LinkedIn" />
                    </a>
                </li>  
            </div>    
        </div>
    )
}

export default Contacts;  